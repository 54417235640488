<template>
  <v-row align="baseline">
    <v-col cols="12">
      <v-autocomplete
        v-model="participating"
        :label="select_label"
        :hint="hint"
        :persistent-hint="hint ? true : false"
        :items="reserves"
        :readonly="readonly"
        chips
        deletable-chips
        item-text="name"
        return-object
        multiple
        @change="$emit('change')"
      ></v-autocomplete>
    </v-col>
    <v-col v-for="reserve in filteredParticipating" :key="reserve.id">
      <v-text-field
        class="py-0 mx-2"
        v-model.number="value[reserve.id]"
        :error-messages="$utils.getIfExists(errorMessages, [reserve.id, 'messages'])"
        :label="`${reserve.name} ${label}`"
        :suffix="suffix"
        :type="type"
        :readonly="readonly"
        @change="$emit('change')"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "InputReserve",
  props: {
    label: {
      type: String,
      required: false,
    },
    hint: {
      type: String,
      required: false,
    },
    select_label: {
      type: String,
      required: false,
      default: "Participating Reserves",
    },
    suffix: {
      type: String,
      required: false,
    },
    value: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    reserves: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Object,
      required: false,
    },
  },
  created() {
    this.value.forEach((el, idx) => {
      if (el !== null) {
        const reserve = this.reserves.find((e) => e.id === idx);
        this.participating.push(reserve);
      }
    });
  },
  computed: {
    filteredParticipating() {
      return this.participating.filter((e) => e != null);
    },
  },
  data() {
    return {
      participating: [],
    };
  },
};
</script>
